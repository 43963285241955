.table-sm {
  th {
    padding: $table-cell-padding;
  }
}

.btn-fs-add {
    @include button-variant(#4a89dc, #4a89dc);
}

.btn-fs-save {
    @include button-variant(#37bc98, #37bc98);
}

.btn-fs-del {
    @include button-variant(#da4453, #da4453);
}

.btn-fs-flt {
    @include button-variant(#48cfad, #48cfad);
}

.btn-fs-cncl {
    @include button-variant(#95a5a6, #95a5a6);
}

.btn-fs-exp {
    @include button-variant(#2c3e50, #2c3e50);
}

.btn-fs-img {
    @include button-variant(#f1c40f, #f1c40f);
}

.btn-fs-cmnt {
    @include button-variant(#e67e22, #e67e22);
}